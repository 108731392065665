











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class cBalanceDisplay extends Vue {
  @Prop() balance!: number;

  @Prop() inEuro!: boolean;

  get balanceParts(): {beforeDot: string; afterDot: string } {
    const [beforeDot, afterDot] = String(this.balance.toFixed(2)).split('.');
    return {
      beforeDot,
      afterDot,
    };
  }
}

